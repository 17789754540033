const defaultValues = {
  transition: 'all 0.75s',
};

export default {
  colors: {
    primary: '#ED1B24',
    primaryDark: '#c51017',
    secondary: 'black',
    background: 'white',
    background1: '#EDEEF0',
    background2: '#21262D',
    background3: '#EAEEF5',
    text1: '#21262d',
    text2: '#4f5a60',
    text3: '#CCC',
    text4: '#c5d3db',
  },
  fonts: {
    body: 'Roboto Slab, sans-serif',
    heading: 'League Gothic, sans-serif',
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  fontWeights: {
    body: 400,
    bold: 700,
  },
  text: {
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      letterSpacing: '0.035em',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
    },
    h1: {
      variant: 'text.heading',
      fontSize: 5,
    },
    h2: {
      variant: 'text.heading',
      fontSize: 4,
    },
    h3: {
      variant: 'text.heading',
      fontSize: 3,
    },
    h4: {
      variant: 'text.heading',
      fontSize: 2,
    },
    h5: {
      variant: 'text.heading',
      fontSize: 1,
    },
    h6: {
      variant: 'text.heading',
      fontSize: 0,
    },
  },
  links: {
    sideMenu: {
      color: 'white',
      fontWeight: 'bold',
      fontFamily: 'heading',
      fontSize: '24px',
      textDecoration: 'none',
      zIndex: 20,
      backgroundColor: 'black',
      padding: '20px',
    },
    footer: {
      display: 'block',
      color: 'text4',
      textDecoration: 'none',
      heading: { color: 'white', marginBottom: '10px', fontWeight: '100' },
    },
    nav: {
      ...defaultValues,
      letterSpacing: '0.05em',
      fontSize: '24px',
      textDecoration: 'none',
      color: 'text2',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      height: '60px',
      // border: '1px solid lightgrey',
      borderRight: '1px solid lightgrey',
      borderLeft: '1px solid lightgrey',
      width: '100%',
      textAlign: 'center',
      fontFamily: 'heading',
      // fontSize: '24px',
      display: ['none', 'none', 'flex'],
      '&:hover': {
        color: 'white',
        backgroundColor: 'secondary',
      },
    },
  },
  a: {
    button: {
      ...defaultValues,
      color: 'white',
      backgroundColor: 'primary',
      height: '60px',
      cursor: 'pointer',
      fontSize: '24px',
      letterSpacing: '0.075em',
      overflow: 'hidden',
      textTransform: 'uppercase',
      borderRadius: '3px',
      fontFamily: 'heading',
      fontWeight: 'bold',
      padding: '20px',
      borderTopRightRadius: '0',
      borderBottomRightRadius: '0',
      textDecoration: 'none',
      display: 'flex',
      '&:hover': {
        backgroundColor: 'primaryDark',
      },
    },
  },
};
