const React = require('react');

exports.onInitialClientRender = () => {
  return (
    <div>
      <script
        src="//fast.wistia.com/embed/medias/j38ihh83m5.jsonp"
        async
      ></script>
      <script src="//fast.wistia.com/assets/external/E-v1.js" async></script>
    </div>
  );
};
